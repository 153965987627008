<template>
  <div class="aq__modal__wrp" @click="closeModal">
    <div class="aq__modal">
      <div class="top">
        <div class="details">
          <strong>{{$t('appoitment.title')}}</strong>
        </div>
        <div class="close__btn" @click="$emit('close')">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.40294 7.99965L0.503906 14.8987L1.10379 15.4986L8.00282 8.59953L14.9019 15.4986L15.5017 14.8987L8.60271 7.99965L15.501 1.10131L14.9012 0.501431L8.00282 7.39976L1.10449 0.501431L0.504607 1.10131L7.40294 7.99965Z" fill="white"/>
          </svg>
        </div>
      </div>
      <form v-show="!hidden">
        <strong>{{$t('appoitment.namePhone')}}</strong>
        <div class="inp__row">
          <input type="text" v-model="name" :placeholder="$t('appoitment.name')">
          <input type="text" v-model="phone" :placeholder="$t('appoitment.phone')">
        </div>
        <strong>{{translateText[lang].comment}}</strong>
        <div class="inp__row">
          <textarea class="comment" v-model="comment" :placeholder="$t('appoitment.comment')"></textarea>
        </div>
        <button type="button" @click="sendData" :disabled="isFormSend">{{$t('appoitment.btnModal')}}</button>
      </form>
      <div class="container" v-show="hidden">
        <img class="logo" src="/img/logo2.svg" alt="">
        <h3 class="title_end">{{$t('appoitment.message')}}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import {mapGetters} from 'vuex';

const domain = process.env.VUE_APP_BASE_API;

export default {
  props: {
    userId: {
      required: true
    }
  },
  data: () => ({
    name: '',
    phone: '',
    comment: '',
    hidden: false,
    isFormSend: false,
    translateText: {
      ru:{
        title: 'Записаться',
        namePhone: 'Имя и номера телефона',
        comment: 'Комментарий',
        name: 'Имя',
        phone: 'Телефон',
        btnModal: 'Отправить',
        message: 'Ваша заявка была отправлена!'
      },
      ro: {
        title: 'Înscrie-te',
        namePhone: 'Numele și numerele de telefon',
        comment: 'Comentariu',
        name: 'Numele',
        phone: 'Telefone',
        btnModal: 'Salvează',
        message: 'Solicitarea dvs a fost transmisa!'
      },
      en: {
        title: 'Sign up',
        namePhone: 'Date and time',
        comment: 'Comment',
        name: 'Name',
        phone: 'Phone',
        btnModal: 'Send',
        message: 'Your application has been sent!'
      }
    }
  }),
  components: {
    VueTimepicker
  },
  methods: {
    googleEventDoctorBooking(){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event' : 'Doctor_Booking_Complete'
      });
    },
    async sendData(){
      if(this.name.length < 3 || this.phone.length < 5){
        return;
      }
      this.isFormSend = true;
      await fetch(`${domain}/cabinet/appointment/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          client_name: this.name,
          client_phone: this.phone,
          client_comments: this.comment,
          confirmation: false,
          doctor: this.userId,
          lang: this.lang,
          utm_tags: this.$store.getters['utmQuery/strUtm']
        })
      });
      this.isFormSend = false;
      this.googleEventDoctorBooking();
      this.hidden = true;
    },
    closeModal(e){
     if(e.target.classList == 'aq__modal__wrp active'){
      this.$emit('close');
      this.hidden = false;
     }
    }
  },
  computed: {
    ...mapGetters({
      lang: 'languages/getLang', 
    })
  }
  
}
</script>

<style lang="scss">
.aq__modal__wrp {

  .comment {
    resize: none;
    width: 100%;
    height: 90px;
    border: none;
    background: #EFEEFE;
    border-radius: 6px;
    outline: none;
    padding: 20px;
  }

  .container {
    width: 100%;
    padding: 0 36px;
  }
  .logo {
    display: block;
    width: 200px;
    margin: auto;
  }
  .title_end {
    text-align: center;
    color: #89227F;
    padding: 50px 0;
  }

  button:disabled {
    background: #ccc;
  }

}

</style>
